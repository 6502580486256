<template>
  <div>
    <detail-header></detail-header>
    <div class="section">
      <h2 class="title">用户奖励金额</h2>
      <el-form
        ref="form"
        :model="setting"
        :rules="ruleValidate"
        label-width="110px"
      >
        <el-row>
          <el-col :offset="8" :span="8">
            <el-form-item label="普通用户推荐奖励金额" prop="amount">
              <el-input
                placeholder="请输入金额"
                v-model="setting.amount"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :offset="8" :span="8">
            <el-form-item label="优惠券设置" prop="couponIds">
              <el-select
                multiple
                placeholder="请选择优惠券"
                v-model="setting.couponIds"
              >
                <el-option
                  v-for="(item, index) in couponList"
                  :key="index"
                  :label="item.couponName"
                  :value="item.id+''"
                >
                  <span style="float: left">{{ item.couponName }}</span>
                  <span
                    style="
                      float: right;
                      color: var(--el-text-color-secondary);
                      font-size: 13px;
                    "
                    >金额：{{ item.couponPrice / 100 }};有效期：{{ item.days }};
                    限额：{{ item.couponRule / 100 }}</span
                  >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col style="text-align: center">
            <el-button type="primary" @click="doSave">保存</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import { fetchPusherSetting, updatePusherSetting } from "@/api/setting";
import { fetchCouponList } from "@/api/coupon";

export default {
  data() {
    return {
      setting: {},
      couponList: [],
      ruleValidate: {
        amount: [
          {
            required: true,
            message: "请输入金额",
          },
        ],
      },
    };
  },

  mounted() {
    this.getData();
    this.getCouponList();
  },

  methods: {
    getCouponList() {
      fetchCouponList({
        status: 1,
        pageIndex: 1,
        pageSize: 100,
      }).then((res) => {
        this.couponList = res.data.data.records;
      });
    },
    doSave() {
      this.$refs["form"].validate((res) => {
        if (res) {
          let amount = this.setting.amount * 100;
          updatePusherSetting({
            amount: amount,
            couponIds: this.setting.couponIds,
          }).then((res) => {
            this.$message.success("操作成功");
            this.getData();
          });
        }
      });
    },
    getData() {
      fetchPusherSetting().then((res) => {
        let obj = res.data.data;

        this.setting = {
          amount : obj.amount / 100,
          couponIds : obj.couponIds
        }
      });
    },
  },
};
</script>

<style scoped>
.section {
  border-bottom: 1px solid #e1e1e1;
  padding: 30px 10px;
}
.section .title {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 20px;
}
</style>